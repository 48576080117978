<template>
  <div>

    <div class="row">
        <div class="col-3 text-white">
            <div class="card shadow-base bd-0">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                  <h6 class="card-title tx-uppercase tx-12 mg-b-0 text-white">Won</h6>
                </div><!-- card-header -->
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                  <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{ stats.won }}</h4>
                </div><!-- card-body -->
            </div>
        </div>
        <div class="col-3 text-white">
            <div class="card shadow-base bd-0">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                  <h6 class="card-title tx-uppercase tx-12 mg-b-0 text-white">Lost</h6>
                </div><!-- card-header -->
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                  <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{ stats.lost }}</h4>
                </div><!-- card-body -->
            </div>
        </div>
        <div class="col-3 text-white">
            <div class="card shadow-base bd-0">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                  <h6 class="card-title tx-uppercase tx-12 mg-b-0 text-white">Withdrawn</h6>
                </div><!-- card-header -->
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                  <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{ stats.withdrawn }}</h4>
                </div><!-- card-body -->
            </div>
        </div>
        <div class="col-3 text-white">
            <div class="card shadow-base bd-0">
                <div class="card-header bg-transparent d-flex justify-content-between align-items-center">
                  <h6 class="card-title tx-uppercase tx-12 mg-b-0 text-white">Pending</h6>
                </div><!-- card-header -->
                <div class="card-body d-xs-flex justify-content-between align-items-center">
                  <h4 class="mg-b-0 tx-white tx-lato tx-bold">{{ stats.pending }}</h4>
                </div><!-- card-body -->
            </div>
        </div>
    </div>
      <div class="row" style="margin-top: 20px;">
          <div class="col">

              <div class="card">
                  <div class="card-header">
                      <h4>
                          POPLA Appeals
                          <button class="btn btn-sm btn-primary float-right" v-if="$can('claims.popla.create')" @click="openCreateModal">Log Appeal</button>
                      </h4>
                  </div>
                  <div class="card-body">

                      <pagination :data="pagination" :show-count="true" @search="getAppeals"></pagination>

                      <table class="table">
                          <thead class="thead-primary">
                              <tr>
                                  <th>#</th>
                                  <th>Created By</th>
                                  <th>POPLA Reference</th>
                                  <th>Case Reference</th>
                                  <th>Submission Date</th>
                                  <th>Status</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-if="appeals.length < 1 && !loading">
                                  <td colspan="7" class="text-center">No appeals found.</td>
                              </tr>
                              <tr v-if="loading">
                                  <td colspan="7" class="text-center">Loading...</td>
                              </tr>
                              <tr v-for="appeal in appeals" v-if="!loading">
                                  <td>{{ appeal.id }}</td>
                                  <td>{{ appeal.created_by_email }}</td>
                                  <td>{{ appeal.popla_reference }}</td>
                                  <td>{{ appeal.claim_reference }}</td>
                                  <td>{{ appeal.submission_date_formatted }}</td>
                                  <td>{{ appeal.status }}</td>
                                  <td>
                                      <button v-if="$can('claims.popla.update') && appeal.status == 'Pending'" class="btn btn-sm btn-success mg-r-10" @click="won(appeal)">Won</button>
                                      <button v-if="$can('claims.popla.update') && appeal.status == 'Pending'" class="btn btn-sm btn-danger mg-r-10" @click="lost(appeal)">Lost</button>
                                      <button v-if="$can('claims.popla.update') && appeal.status == 'Pending'" class="btn btn-sm btn-primary mg-r-10" @click="withdrawnMotorist(appeal)">Withdrawn - Motorist</button>
                                      <button v-if="$can('claims.popla.update') && appeal.status == 'Pending'" class="btn btn-sm btn-primary mg-r-10" @click="withdrawnOperator(appeal)">Withdrawn - Operator</button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>

                      <pagination :data="pagination" :show-count="true" @search="getAppeals"></pagination>
                      
                  </div>
              </div>

          </div>
      </div>

      <edit-modal id="createAppealModal" title="Create Appeal">

          <div class="form-layout form-layout-2">
              <div class="row no-gutters">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label class="form-control-label">POPLA Reference: <span class="tx-danger">*</span></label>
                          <input class="form-control" type="text" v-model="appeal.popla_reference" placeholder="POPLA reference number">
                      </div>
                  </div>
              </div><!-- row -->
              <div class="row no-gutters">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label class="form-control-label">Case Reference: <span class="tx-danger">*</span>
                            <span v-if="appeal.claim_reference.length > 0 && !reference_verified && !locating && verification_message" class="tx-warning" style="margin-left: 10px;">{{ verification_message }}</span> 
                            <span v-if="locating" class="tx-primary" style="margin-left: 10px;">Attempting to locate case from reference</span>
                            <span v-if="!locating && reference_verified" class="tx-success" style="margin-left: 10px;">Reference verified!</span>
                          </label>
                          <input class="form-control" @focusout="locateClaim" type="text" v-model="appeal.claim_reference" placeholder="Case reference number">
                      </div>
                  </div>
              </div><!-- row -->
              <div class="row no-gutters">
                  <div class="col-md-12">
                      <div class="form-group">
                          <label class="form-control-label">Submission Due Date: <span class="tx-danger">*</span></label>
                          <input class="form-control" type="date" v-model="appeal.submission_date" placeholder="Submission due date">
                      </div>
                  </div>
              </div><!-- row -->
              <div class="form-layout-footer bd pd-20 bd-t-0">
                  <submit-button :disabled="!reference_verified" :clicked="creating" :background="'btn btn-info'" :text="'Save Changes'" @click="createAppeal" />
                  <button class="btn btn-secondary" id="closeCreateAppealModal" data-dismiss="modal">Cancel</button>
              </div><!-- form-group -->
          </div>

      </edit-modal>

  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';
import pagination from '@/components/Pagination.vue';
import {DateTime} from 'luxon';
import EditModal from '@/components/EditModal.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import Swal from 'sweetalert2';

export default {
  name: 'Popla',
  components: {
      pagination,
      EditModal,
      SubmitButton
  },
  data(){
      return {
          appeals: [],
          pagination: {
              currPage: 1,
              perPage: 25,
              total: 0,
              from: 1,
              to: 0,
              lastPage: 0,
              totalResults: 0,
              maxPage: 0
          },
          filters: {
              reference: null,
              claim_type: null,
              date_received: null
          },
          loading: false,
          currentAppeal: null,
          updating: false,
          appeal: {
            popla_reference: '',
            claim_reference: '',
            submission_date: ''
          },
          creating: false,
          deleting: false,
          reference_verified: false,
          verification_message: null,
        locating: false,
        stats: {
            won: 0,
            lost: 0,
            withdrawn: 0,
            pending: 0
        }
      }
  },
  mounted(){
      this.getAppeals();
      this.getStats();
  },
  methods: {
    getStats(){
        axios.get(`https://api.varsanpr.com/api/popla/stats`, {
            headers: authHeader()
        })
        .then(response => {
            this.stats = response.data.stats;
        })
        .catch(err => {
            this.$error(err?.response?.data || err?.message || err, err);
        });
    },
      getAppeals(page = 1){
          if(this.loading) return;
          this.loading = true;
          axios.get(`https://api.varsanpr.com/api/popla?page=${page}&count=${this.pagination.perPage}`, {
              headers: authHeader(),
              params: this.filters
          })
          .then(res => {
              this.appeals = res.data.appeals;
              this.pagination.currPage = parseInt(res.data.pagination.page);
              this.pagination.totalResults = res.data.pagination.total;
              this.pagination.from = this.pagination.currPage * this.pagination.perPage - this.pagination.perPage + 1;
              this.pagination.to = this.pagination.currPage * this.pagination.perPage;
              this.pagination.lastPage = Math.ceil(parseInt(res.data.pagination.total) / parseInt(this.pagination.perPage));
              this.pagination.maxPage =  Math.ceil(parseInt(res.data.pagination.total) / parseInt(this.pagination.perPage));


              this.appeals.forEach((appeal) => {
                appeal.submission_date_formatted = DateTime.fromJSDate(new Date(appeal.submission_date)).toFormat('dd/MM/yyyy');
                appeal.submission_date = DateTime.fromJSDate(new Date(appeal.submission_date)).toFormat('yyyy-MM-dd');
              });
          })
          .catch(err => {
              this.$error(err?.response?.data?.message || err?.message || err, err);
          })
          .finally(() => {
              this.loading = false;
          })
      },
      openCreateModal(){
          $('#createAppealModal').modal('show');
      },
      createAppeal(){
          if(this.creating) return;
          this.creating = true;

          axios.post(`https://api.varsanpr.com/api/popla`, {
                popla_reference: this.appeal.popla_reference,
                claim_reference: this.appeal.claim_reference,
                submission_date: this.appeal.submission_date
          }, {
              headers: authHeader()
          })
          .then(res => {
              this.$success('POPLA Appeal created successfully.');
              $('#closeCreateAppealModal').click();
              this.getAppeals();
              this.appeal = {
                popla_reference: '',
                claim_reference: '',
                submission_date: ''
              };
          })
          .catch(err => {
              this.$error(err?.response?.data || err?.message || err, err);
          })
          .finally(() => {
              this.creating = false;
          });
      },
      locateClaim(){
        this.locating = true;
        this.reference_verified = false;
        this.verification_message = null;

        axios.get(`https://api.varsanpr.com/api/popla/locate`, {
            params: {
                reference: this.appeal.claim_reference
            },
            headers: authHeader()
        })
        .then(response => {
            if(response.data.success){
                this.reference_verified = true;
            }else{
                this.verification_message = response.data.message;
            }
        })
        .catch(err => {
            this.$error(err?.response?.data || err?.message || err, err);
        })
        .finally(() => {
            this.locating = false;
        });
      },
      async won(appeal){
        const {value: reason} = await Swal.fire({
            title: `Please copy and paste the assessors reasoning for marking this appeal as won.`,
            input: 'textarea',
            inputPlaceholder: 'Input assessors reasoning',
            showCancelButton: true
        });

        if(reason){
            axios.patch(`https://api.varsanpr.com/api/popla`, {
                id: appeal.id,
                status: 'Won',
                assessors_reason: reason,
                assessment_date: DateTime.local().toFormat('yyyy-MM-dd')
            }, {
                headers: authHeader()
            })
            .then(res => {
                this.$success('POPLA Appeal marked as won successfully.');
                this.getAppeals();
            })
            .catch(err => {
                this.$error(err?.response?.data || err?.message || err, err);
            });
        }else{
            this.$error('You must input a reason to mark this appeal as won.');
        }
      },
      async lost(appeal){
        const {value: result} = await Swal.fire({
            title: `Are you sure you wish to mark this appeal as Lost at POPLA?`,
            showCancelButton: true,
            confirmButtonText: 'Update Appeal',
            cancelButtonText: 'Cancel',
        });

        if(result){
            axios.patch(`https://api.varsanpr.com/api/popla`, {
                id: appeal.id,
                status: 'Lost',
                assessment_date: DateTime.local().toFormat('yyyy-MM-dd')
            }, {
                headers: authHeader()
            })
            .then(res => {
                this.$success('POPLA Appeal marked as lost successfully.');
                this.getAppeals();
            })
            .catch(err => {
                this.$error(err?.response?.data || err?.message || err, err);
            });
        }
      },
      async withdrawn(appeal){
        const {value: result} = await Swal.fire({
            title: `Are you sure you wish to mark this appeal as Withdrawn at POPLA?`,
            showCancelButton: true,
            confirmButtonText: 'Update Appeal',
            cancelButtonText: 'Cancel',
        });

        if(result){
            axios.patch(`https://api.varsanpr.com/api/popla`, {
                id: appeal.id,
                status: 'Withdrawn',
                assessment_date: DateTime.local().toFormat('yyyy-MM-dd')
            }, {
                headers: authHeader()
            })
            .then(res => {
                this.$success('POPLA Appeal marked as withdrawn successfully.');
                this.getAppeals();
            })
            .catch(err => {
                this.$error(err?.response?.data || err?.message || err, err);
            });
        }
      },
      async withdrawnMotorist(appeal){
        const {value: result} = await Swal.fire({
            title: `Are you sure you wish to mark this appeal as Withdrawn by Motorist?`,
            showCancelButton: true,
            confirmButtonText: 'Update Appeal',
            cancelButtonText: 'Cancel',
        });

        if(result){
            axios.patch(`https://api.varsanpr.com/api/popla`, {
                id: appeal.id,
                status: 'Withdrawn - Motorist',
                assessment_date: DateTime.local().toFormat('yyyy-MM-dd')
            }, {
                headers: authHeader()
            })
            .then(res => {
                this.$success('POPLA Appeal marked as withdrawn successfully.');
                this.getAppeals();
            })
            .catch(err => {
                this.$error(err?.response?.data || err?.message || err, err);
            });
        }
      },
      async withdrawnOperator(appeal){
        const {value: result} = await Swal.fire({
            title: `Are you sure you wish to mark this appeal as Withdrawn by Operator?`,
            showCancelButton: true,
            confirmButtonText: 'Update Appeal',
            cancelButtonText: 'Cancel',
        });

        if(result){
            axios.patch(`https://api.varsanpr.com/api/popla`, {
                id: appeal.id,
                status: 'Withdrawn - Operator',
                assessment_date: DateTime.local().toFormat('yyyy-MM-dd')
            }, {
                headers: authHeader()
            })
            .then(res => {
                this.$success('POPLA Appeal marked as withdrawn successfully.');
                this.getAppeals();
            })
            .catch(err => {
                this.$error(err?.response?.data || err?.message || err, err);
            });
        }
      }
  }
}
</script>