<template>

    <div :id="id" class="modal fade" style="display: none; padding-right: 17px;">
        <div class="modal-dialog modal-lg modal-dialog-centered" :class="{'modal-xl': large}" role="document">
            <div class="modal-content bg-dark tx-white tx-size-sm">
                <div class="modal-header bg-dark pd-x-20">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">{{title}}</h6>
                    <button type="button" class="close tx-white" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="tx-white">×</span>
                    </button>
                </div>
                <div class="modal-body bg-dark tx-white pd-20">

                    <slot></slot>

                </div><!-- modal-body -->
                <div class="modal-footer bg-dark tx-white">
                    <button id="closeEditModal" type="button" class="btn btn-secondary tx-size-xs" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div><!-- modal-dialog -->
    </div>

</template>

<script>
export default {
    name: "EditModal",
    props: {
        id: String,
        title: String,
        large: Boolean,
    }
}
</script>

<style scoped>

</style>
